import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { AdminInternal, Login } from "./routes";
import TheLayoutAdmin from "./container/TheLayoutAdmin";
import TheLoginLayOut from "./container/TheLoginLayOut";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Index = () => {
  const token = useSelector((state) => state.authReducer.token);
  const { user } = useSelector((state) => state.authReducer);

  return (
    <Suspense
      fallback={
        <div className="admin-container-layout-page">
          <FadeLoader />
        </div>
      }
    >
      <Routes>
        <Route
          path="/"
          element={
            token && user.role === "Admin" ? (
              <Navigate to="/dashboard" />
            ) : (
              <TheLoginLayOut />
            )
          }
        >
          {Login.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={<route.component />}
            />
          ))}
        </Route>
        <Route
          path="/"
          element={
            token && user.role === "Admin" ? (
              <TheLayoutAdmin />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        >
          {AdminInternal.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={<route.component />}
            />
          ))}
        </Route>
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Suspense>
  );
};
function App() {
  return (
    <BrowserRouter>
      <Index />
    </BrowserRouter>
  );
}

export default App;
