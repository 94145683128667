import React from "react";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const TheLoginLayOut = () => {
  const statusLoading = useSelector((state) => state.globalLoading.status);
  return (
    <LoadingOverlayWrapper active={statusLoading} spinner>
      <div>
        <Outlet />
      </div>
      <ToastContainer />
    </LoadingOverlayWrapper>
  );
};

export default TheLoginLayOut;
