import React from "react";

const Home = React.lazy(() => import("./pages/Home"), "Home");
const Billing = React.lazy(() => import("./pages/Billing"), "Billing");
const Profile = React.lazy(() => import("./pages/Profile"), "Profile");
const Tables = React.lazy(() => import("./pages/Tables"), "Tables");
const SignUp = React.lazy(() => import("./pages/SignUp"), "SignUp");
const SignIn = React.lazy(() => import("./pages/SignIn"), "SignIn");
const Contract = React.lazy(
  () => import("./pages/pawnManage/contract/Contract"),
  "Contract"
);
export const AdminInternal = [
  { path: "/", exact: true, name: "Home", component: Home },
  { path: "/dashboard", exact: true, name: "Home", component: Home },
  { path: "/tables", exact: true, name: "Tables", component: Tables },
  { path: "/billing", exact: true, name: "Billing", component: Billing },
  // { path: "/rtl", exact: true, name: "Rtl", component: Rtl },
  { path: "/profile", exact: true, name: "Profile", component: Profile },
  { path: "/hop-dong", exact: true, name: "Hợp Đồng", component: Contract },
];

export const Login = [
  { path: "/", exact: true, name: "SignIn", component: SignIn },
  { path: "/sign-in", exact: true, name: "SignIn", component: SignIn },
  { path: "/sign-up", exact: true, name: "SignUp", component: SignUp },
];
