import React from "react";
import { Outlet } from "react-router-dom";
import Main from "../components/layout/Main";
import { useSelector } from "react-redux";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { ToastContainer } from "react-toastify";

const TheLayoutAdmin = () => {
  const statusLoading = useSelector((state) => state.globalLoading.status);
  return (
    <LoadingOverlayWrapper active={statusLoading} spinner>
      <Main>
        <Outlet />
      </Main>
      <ToastContainer />
    </LoadingOverlayWrapper>
  );
};

export default TheLayoutAdmin;
