import { jwtDecode } from "jwt-decode";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";

export const controlLoading = (status) => {
  return {
    type: "CONTROL_LOADING",
    status,
  };
};

export const controlShowSidebar = (status) => {
  return {
    type: "CONTROL_SIDEBAR",
    status,
  };
};

export const loadingOverView = (payload) => {
  return {
    type: "LOADING_OVERVIEW",
    payload,
  };
};
export const endLoadingOverView = (payload) => {
  return {
    type: "ENDLOADING_OVERVIEW",
    payload,
  };
};
export const loadingMember = (payload) => {
  return {
    type: "LOADING_MEMBER",
    payload,
  };
};
export const endLoadingMember = (payload) => {
  return {
    type: "ENDLOADING_MEMBER",
    payload,
  };
};

export const loadingDataPlanSalesSummary = (payload) => {
  return {
    type: "LOADING_DATAPLANSALESSUMMARY",
    payload,
  };
};
export const endLoadingDataPlanSalesSummary = (payload) => {
  return {
    type: "ENDLOADING_DATAPLANSALESSUMMARY",
    payload,
  };
};

export const loadingDataPlanTransactions = (payload) => {
  return {
    type: "LOADING_DATAPLANTRANSACTIONS",
    payload,
  };
};
export const endLoadingDataPlanTransactions = (payload) => {
  return {
    type: "ENDLOADING_DATAPLANTRANSACTIONS",
    payload,
  };
};

export const loadingDataUsageByCountry = (payload) => {
  return {
    type: "LOADING_DATAUSAGEBYCOUNTRY",
    payload,
  };
};
export const endLoadingDataUsageByCountry = (payload) => {
  return {
    type: "ENDLOADING_DATAUSAGEBYCOUNTRY",
    payload,
  };
};
export const loadingDataAverageMarginMetrics = (payload) => {
  return {
    type: "LOADING_DATAAVERAGEMARGINMETRICS",
    payload,
  };
};
export const endLoadingDataAverageMarginMetrics = (payload) => {
  return {
    type: "ENDLOADING_DATAAVERAGEMARGINMETRICS",
    payload,
  };
};

export const loadingDataGiftCodeSummary = (payload) => {
  return {
    type: "LOADING_DATAGIFTCODESUMMARY",
    payload,
  };
};
export const endLoadingDataGiftCodeSummary = (payload) => {
  return {
    type: "ENDLOADING_DATAGIFTCODESUMMARY",
    payload,
  };
};
export const loginSuccess = (token) => {
  localStorage.setItem("token", token);
  return {
    type: LOGIN_SUCCESS,
    payload: token,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  return {
    type: LOGOUT,
  };
};
export const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  if (action.type === LOGIN_SUCCESS) {
    const decodedToken = jwtDecode(action.payload);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      store.dispatch(logout());
      return;
    }
  }
  return next(action);
};
