let initialState = {
    status: false
};
let showSidebar = (state = initialState, action) => {
    switch (action.type) {
        case 'CONTROL_SIDEBAR':
            state = {
                status: action.status
            };
            return state;
        default:
            return state;
    }
};
export default showSidebar;