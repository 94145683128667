import { applyMiddleware, createStore } from "redux";
// import apReducers from './redux/reducers/index';
import appReducers from "./redux/reducers/index";
import { checkTokenExpirationMiddleware } from "./redux/actions";
import { thunk } from "redux-thunk";

const store = createStore(
  appReducers,
  applyMiddleware(thunk, checkTokenExpirationMiddleware)
);
export default store;
