import { combineReducers } from "redux";
import globalLoading from "./globalLoading";
import showSidebar from "./showSideBar";
import loadingReport from "./loadingReport";
import authReducer from "./loginUser";

const appReducers = combineReducers({
  globalLoading,
  showSidebar,
  loadingReport,
  authReducer,
});

export default appReducers;
