let initialState = {
  overview: false,
  member: false,
  dataPlanSalesSummary: false,
  dataPlanTransactions: false,
  dataUsageByCountry: false,
  dataAverageMarginMetrics: false,
  dataGiftCodeSummary: false,
};
let loadingReport = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_OVERVIEW":
    case "ENDLOADING_OVERVIEW":
      return {
        ...state,
        overview: action.payload,
      };
    case "LOADING_MEMBER":
    case "ENDLOADING_MEMBER":
      return {
        ...state,
        member: action.payload,
      };

    case "LOADING_DATAPLANSALESSUMMARY":
    case "ENDLOADING_DATAPLANSALESSUMMARY":
      return {
        ...state,
        dataPlanSalesSummary: action.payload,
      };

    case "LOADING_DATAPLANTRANSACTIONS":
    case "ENDLOADING_DATAPLANTRANSACTIONS":
      return {
        ...state,
        dataPlanTransactions: action.payload,
      };
    case "LOADING_DATAUSAGEBYCOUNTRY":
    case "ENDLOADING_DATAUSAGEBYCOUNTRY":
      return {
        ...state,
        dataUsageByCountry: action.payload,
      };
    case "LOADING_DATAAVERAGEMARGINMETRICS":
    case "ENDLOADING_DATAAVERAGEMARGINMETRICS":
      return {
        ...state,
        dataAverageMarginMetrics: action.payload,
      };

    case "LOADING_DATAGIFTCODESUMMARY":
    case "ENDLOADING_DATAGIFTCODESUMMARY":
      return {
        ...state,
        dataGiftCodeSummary: action.payload,
      };
    default:
      return state;
  }
};
export default loadingReport;
